import _path from "path";
import _fs from "fs";
import _process from "process";
var exports = {};
var process = _process;
const path = _path;
const fs = _fs;

exports = function (file, stream) {
  stream = stream || process.stdout;
  file = file ? file : undefined && undefined.filename ? path.resolve(path.dirname(undefined.filename), "usage.txt") : path.resolve("usage.txt");
  return function (code) {
    const rs = fs.createReadStream(file);
    rs.pipe(stream);

    if (code) {
      rs.on("close", function () {
        process.exitCode = code;
      });
    }
  };
};

export default exports;